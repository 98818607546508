import styled from 'styled-components'
import { LAYOUT_CONSTANT } from '../../libs/utils/constants'

import { tokens } from '@JOTAJornalismo/jota-design-system'
const { fonts, colors } = tokens

export const LoginContainerRoot = styled.div`
    background: ${colors.transparent.jotaTGrayLighter40};
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;

    @media (min-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        height: 100%;
    }

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        flex-direction: column;
    }
`
export const LoginHeader = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 44.5px;
    top: 44px;

    & > svg {
        width: 32px;
        height: 32px;
    }

    & > svg:hover {
        cursor: pointer;
    }

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        & > svg {
            display: none;
        }
    }
`

export const LoginContainer = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    justify-content: center;
    padding: 44px;
    box-sizing: border-box;

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        & {
            padding: 24px 36px 44px 36px;
            height: 100%;
            width: 100%;
        }
    }

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.SM}px) {
        & {
            padding: 44px 16px;
        }
    }
`

export const BreadcrumbContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    padding: 4px;
    width: 100%;
    justify-content: space-between;

    & > svg {
        display: none;
    }

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        & {
            margin-bottom: 36px;
        }

        & > svg {
            display: block;
            width: 36px;
            height: 36px;
        }
    }
`

export const Logo = styled.div`
    & > svg {
        width: 183px;
        height: 68px;
    }

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        & > svg {
            width: 121px;
            height: 45px;
        }
    }
`

export const Header04 = styled.h4`
    margin: 24px 0 0 0;
    ${fonts.jotaHeading04()};
`
export const Paragraph = styled.p<{ small?: boolean }>`
    margin: 12px 0 0 0;
    ${(props) => (!props.small ? fonts.jotaBody() : fonts.jotaBodySmall())};
    color: ${colors.gray.jotaGrayDark};

    & > strong {
        padding: 0 5px;
    }

    & > a {
        text-decoration: underline;
        color: ${colors.orange.jotaOrange};

        &:hover {
            text-decoration: none;
        }
    }
`

export const MobileText = styled.form`
    display: none;
    color: ${colors.gray.jotaGrayDark};

    a {
        color: ${colors.orange.jotaOrange};
        text-decoration: underline;
    }

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        display: block;
        ${fonts.jotaBodySmall()};
    }
`

export const FormContainer = styled.div`
    margin-top: 36px;
    width: 100%;

    & > div {
        margin-bottom: 24px;
    }

    & > a {
        color: ${colors.orange.jotaOrange};
        ${fonts.jotaBody()};
        text-decoration: underline;
        margin-bottom: 24px;
        display: inline-block;

        &:hover {
            text-decoration: none;
        }
    }
`
export const DialogContainer = styled.div`
    margin: 36px 0 24px 0;
    width: 100%;
`

export const NotProYetContainer = styled.section`
    width: 60%;
    display: flex;
    padding-inline: 44px;
    align-items: center;
    background:
        linear-gradient(
            180deg,
            rgba(31, 33, 40, 0.8) 0%,
            ${colors.black.jotaBlackLight} 100%
        ),
        url('https://jota.pro/img/bg-brasilia-4.jpg') center center;
    background-size: cover;

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        padding: 44px 36px;
        width: 100%;
        box-sizing: border-box;
    }

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.SM}px) {
        padding-inline: 16px;
    }
`

export const TitleJotaPro = styled.h1`
    ${fonts.jotaHeading01()};
    color: ${colors.white.jotaWhite};
    margin: 0px;
    margin-top: 8px;

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        ${fonts.jotaHeading03()};
    }
`

export const ParagraphContent = styled.article`
    color: ${colors.gray.jotaGrayLight};
    ${fonts.jotaBody()};
    max-width: 520px;

    @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
        & > button {
            width: 100%;
        }
    }
`

export const ParagraphContainer = styled.div`
    display: flex;
`

export const UnderButtonText = styled.p`
    margin-top: 36px;
    margin-bottom: 0px;

    & > strong {
        text-decoration: underline;
    }
`

export const TextJotaPro = styled.p`
    a {
        text-decoration: underline;
        color: ${colors.white.jotaWhite};
    }

    & > p {
        margin-block: 44px 36px;
        & > a {
            text-decoration: underline;
        }

        @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
            margin-block: 24px 36px;
        }
    }

    & > h5 {
        ${fonts.jotaHeading05()};
        color: ${colors.white.jotaWhite};
        margin-block: 44px 16px;

        @media (max-width: ${LAYOUT_CONSTANT.BREAKPOINTS.MD}px) {
            margin-block: 24px 16px;
        }
    }
`
