import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from 'styled-components'
// import { BREAKPOINTS } from '../../utils/constants'

export const LoadingLogin = () => {
    return (
        <LoginLoadingContainer>
            <LoginFieldLoading />
            <LoginFieldLoading />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '24px'
                }}
            >
                <Skeleton
                    style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '5px'
                    }}
                />
                <Skeleton
                    style={{
                        height: '20px',
                        width: '150px',
                        marginBottom: '5px'
                    }}
                />
            </div>

            <Skeleton
                style={{
                    height: '20px',
                    width: '150px',
                    marginTop: '24px'
                }}
            />

            <Skeleton
                style={{ height: '40px', width: '100%', marginTop: '24px' }}
            />
        </LoginLoadingContainer>
    )
}

export const LoginFieldLoading = () => {
    return (
        <LoginFieldContainer>
            <Skeleton
                style={{ height: '20px', width: '120px', marginBottom: '5px' }}
            />
            <Skeleton
                style={{ height: '40px', width: '100%', marginBottom: '18px' }}
            />
        </LoginFieldContainer>
    )
}

const LoginLoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 36px;
`

const LoginFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`
