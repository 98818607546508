/**
 * Validate Email
 * Description: Check if email is valid or not
 * @param {string} email
 * @return {boolean}
 */
export const validateEmail = (email: string): boolean => {
    const re = /^[^@]+@[^@]+\.[^@\.]{2,}$/
    return re.test(String(email).toLowerCase())
}
